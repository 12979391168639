import React from 'react';
import MainLayout from '@/layouts/MainLayout';
import { graphql, PageProps } from 'gatsby';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { InterestedToLearnMore } from '@/components/sections';
import { ProductCard } from '@/components/cards';
import SolutionSVG from '@/icons/solution.inline.svg';
import Seo from '@/components/Seo';
import DataCenterSVG from '@/icons/data-center.inline.svg';

type ProductPageProps = PageProps<GatsbyTypes.ProductPageQuery>;

const ProductPage: React.FC<ProductPageProps> = ({ data }) => {
  const { t } = useTranslation();
  const { language } = useI18next();

  const { products } =
    (language === 'th' ? data.productsTH : data.productsEN)?.pages?.product
      ?.section_3?.components || {};

  return (
    <MainLayout>
      <Seo title={t('seo.product.title')} description={t('seo.product.desc')} />

      {/* Section 1: Enterprise-Grade Cold Data Storage */}
      <section className="full-page flex flex-col items-start lg:items-center lg:flex-row">
        <div className="h-2/5 lg:h-full px-4 md:px-6 lg:px-16 xl:px-28 2xl:pl-0 py-28 w-full lg:w-1/2 flex flex-col justify-center space-y-4 lg:max-w-screen-sm lg:ml-auto">
          <div className="bg-secondary-main h-8 w-8 rounded-full" />
          <h1 className="font-semibold text-primary-main text-4xl md:text-5xl whitespace-pre-line lg:whitespace-normal !leading-snug">
            {t('pages.product.section-1.header-1')}
          </h1>
          <h2 className="text-primary-main text-xl md:text-2xl">
            {t('pages.product.section-1.desc-1')}
          </h2>
        </div>

        <div className="px-4 h-3/5 lg:h-full flex items-start lg:items-center justify-center md:px-6 lg:px-16 xl:px-28 2xl:pr-0 lg:py-28 w-full lg:w-1/2 bg-gradient-to-b lg:bg-gradient-to-r from-transparent via-secondary-focus to-secondary-focus">
          <SolutionSVG className="w-full lg:max-w-screen-sm lg:mr-auto" />
        </div>
      </section>

      {/* Section 2: About The Product */}
      <section className="bg-primary-main">
        <div className="px-4 py-28 lg:py-32 md:px-6 lg:px-16 xl:px-28 2xl:px-0 max-w-7xl mx-auto text-white text-center">
          <div className="flex flex-col items-center justify-center space-y-5">
            <h2 className="text-4xl font-semibold text-neutral-50">
              {t('pages.product.section-2.header-1')}
            </h2>
            <div className="w-16 bg-secondary-main h-0.5" />
          </div>
          <p className="text-lg text-neutral-50 mt-10">
            {t('pages.product.section-2.desc-1')}
          </p>
        </div>
      </section>

      {/* Section 3: Product's Values */}
      <section className="relative px-4 py-28 lg:py-32 md:px-6 lg:px-16 xl:px-28 2xl:px-0 max-w-7xl mx-auto space-y-24">
        <div className="flex flex-col items-center justify-center space-y-5">
          <h2 className="text-4xl font-semibold text-center text-primary-main">
            {t('pages.product.section-3.header-1')}
          </h2>
          <div className="w-16 bg-secondary-main h-0.5" />
        </div>
        <div className="flex flex-col md:flex-row md:flex-wrap space-y-10 md:space-y-0">
          {products?.map((product, key) => {
            const { title, desc, icon } = product || {};
            return (
              <ProductCard
                title={title}
                desc={desc}
                key={key}
                icon={icon?.childImageSharp?.gatsbyImageData}
                className="md:w-1/2 md:p-4 lg:w-1/4"
              />
            );
          })}
        </div>
        <div className="absolute left-0 -top-24 bg-secondary-main blur-lg opacity-10 w-72 h-72 rounded-full" />
        <div className="absolute right-0 bottom-0 bg-secondary-main blur-lg opacity-10 w-72 h-72 rounded-full" />
      </section>

      {/* Section 4: About Data Center */}
      <section className="bg-primary-main overflow-hidden">
        <div className="flex flex-col lg:space-x-10 lg:flex-row items-center lg:justify-between px-4 py-32 md:px-6 lg:px-16 xl:px-28 2xl:px-0 max-w-7xl mx-auto">
          <DataCenterSVG className="lg:w-1/2 w-full" />
          <div className="flex flex-col lg:w-1/2">
            <h2 className="text-4xl font-semibold text-center lg:text-left text-secondary-main mt-20 lg:mt-0">
              {t('pages.product.section-4.header-1')}
            </h2>
            <h3 className="text-xl text-center lg:text-left text-neutral-50 mt-7">
              {t('pages.product.section-4.desc-1')}
            </h3>
          </div>
        </div>
      </section>

      {/* Section 5: Interested to learn more */}
      <InterestedToLearnMore />
    </MainLayout>
  );
};

export default ProductPage;

export const query = graphql`
  query ProductPage($language: String!) {
    productsEN: enJson {
      pages {
        product {
          section_3 {
            components {
              products {
                title
                desc
                icon {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
    productsTH: thJson {
      pages {
        product {
          section_3 {
            components {
              products {
                title
                desc
                icon {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { eq: "translation" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
