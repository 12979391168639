import React from 'react';
import { PrimaryButton } from '@/components/buttons';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import classNames from 'classnames';

type InterestedToLearnMoreType = {
  className?: string;
};

const InterestedToLearnMore: React.FC<InterestedToLearnMoreType> = ({
  className,
}) => {
  const { t } = useTranslation();

  return (
    <section
      className={classNames(
        `flex flex-col items-center space-y-10 px-4 py-28 lg:py-32 md:px-6 lg:px-16 xl:px-28 2xl:px-0 max-w-7xl mx-auto`,
        className,
      )}
    >
      <h2 className="text-4xl font-semibold text-center">
        &quot;{t('components.sections.interested-to-learn-more.header-1')}&quot;
      </h2>
      {/* <Link to="/about#contact-us">
        <PrimaryButton title={t('components.buttons.contact-us')} />
      </Link> */}

      <Link to="/about" state={{ id: 'contact-us' }}>
        <PrimaryButton title={t('components.buttons.contact-us')} />
      </Link>
    </section>
  );
};

export default InterestedToLearnMore;
